<template>
	<div>
		<!-- 搜索栏 & 标签页 -->
		<order-search :start="startDate" :end="endDate" @handle-start="handleStart" @handle-end="handleEnd"
			@handle-search="getData">
		</order-search>
		<order-tabs v-model="tab" :tabs-list="tabList" @tab-click="getData">
		</order-tabs>
		<!-- 表格 -->
		<center-order-table table-title="制作订单" :table-list="orderTableList">
			<!-- 价格 -->
			<template #price="{price}">
				<div>{{"¥ " + price}}</div>
			</template>
			<!-- 实付款 -->
			<template #payment="{price}">
				<div>{{"¥ "+ price}}</div>
			</template>
			<!-- 交易状态 -->
			<template #status="{status, id}">
				<div>{{status | ostatus}}</div>
				<router-link :to="{
                        name: 'metopeOrder',
                        query:{id}
                    }">
					订单详情
				</router-link>
			</template>
			<!-- 操作 -->
			<template #handle="{row}">
				<div class="order-handle">
					<!-- 待支付 -->
					<a href="javascript:;" v-if="row.orderStatus == 1"
						@click="$router.push({name: 'metopeOrder',query: {id:row.orderID}})">
						支付
					</a>
					<!-- 取消订单 -->
					<a href="javascript:;" v-if="row.orderStatus == 1"
						@click="handleStatus(row.orderID, -1)">
						取消订单
					</a>
          <!-- 申请退款 -->
          <a href="javascript:;" v-if="row.orderStatus == 2"
             @click="handleStatus(row.orderID, 3)">
            申请退款
          </a>
          <!-- 完成订单 -->
          <a href="javascript:;" v-if="row.orderStatus == 2"
             @click="handleStatus(row.orderID, 9)">
            完成订单
          </a>
				</div>
			</template>
		</center-order-table>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import order from '@/mixins/centerOrder'

	export default {
		filters: {
			ostatus(val) {
				switch (Number(val)) {
					case 1:
						return "待付款";
					case 2:
						return "交易中";
        			case 3:
           				return "退款中";
					case 9:
						return "已完成";
          			case -1:
            			return "已取消";
					case -2:
						return "已退款";
				}
			}
		},
		mixins: [order],
		data() {
			return {
				tab: "-3",
				tabList: [{
						value: '-3',
						label: '全部'
					},
					{
						value: '1',
						label: '待付款'
					},
					{
						value: '2',
						label: '交易中'
					},
					{
						value: '3',
						label: '退款中'
					},
					{
						value: '9',
						label: '已完成'
					},
					{
						value: '-1',
						label: '已取消'
					}, {
						value: '-2',
						label: '已退款'
					}
				]
			}
		},
		computed: mapState({
			user_id: state => state.user.user_id,
			orderTableList: state => state.order.orderTableList
		}),
		methods: {
			...mapMutations([
				'handleLoading',
				'setCenterOrder'
			]),
			...mapActions([
				'getCenterOrder',
				'handleOrderStatus'
			]),
			getParams() {
				let userID = this.user_id,
					fromTime = this.startDate,
					toTime = this.endDate,
					orderStatus = this.tab == '-3' ? '' : this.tab;
				return this.$qs.stringify({
					userID,
					fromTime,
					toTime,
					type: 1,
					orderStatus,
				})
			},
			getData() {
				let url = 'usercenter/makerorder',
					params = this.getParams();
				this.getCenterOrder({
					url,
					params
				})
			},
			handleStatus(orderID, orderStatus) {
				this.$confirm('是否改变订单状态？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleLoading(true);

					let url = '/usercenter/makerOrderStatus',
						params = this.$qs.stringify({
							userID: this.user_id,
							orderID,
							status: orderStatus
						});

					return this.handleOrderStatus({
						url,
						params
					})
				}).then(res => {
					let code = res.data.code == 200;
					this.$message({
						showClose: true,
						message: res.data.message,
						type: code ? 'success' : 'error'
					})
					code && this.getData();
				}).catch(() => {}).finally(() => {
					this.handleLoading(false);
				})
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.getData();
			})
		},
		beforeRouteUpdate(to, from, next) {
			next();
		},
		beforeRouteLeave(to, from, next) {
			this.setCenterOrder([]);
			next();
		}
	}
</script>

<style scoped>
	.order-handle>a {
		display: block;
	}
</style>
